<!-- Inner Banner -->
<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Testimonials</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Testimonials</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Client Area -->
<div class="client-area-two ptb-100">
    <div class="container">
        <div class="section-title text-center">
            <span>Clients Review</span>
            <h2>Our Lovely Clients Get Helped From Our Company</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et amet bibendum ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae pellentesque.</p>
        </div>

        <div class="client-slider owl-carousel owl-theme pt-45">
            <div class="client-card">
                <h3>Thomas Smith</h3>
                <span>CEO Of LTD Company</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non ullamcorper tortor, vel mattis mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <div class="client-card-img">
                    <img src="assets/img/client/client-img1.png" alt="Images">
                </div>
            </div>

            <div class="client-card">
                <h3>Sinthy Alina</h3>
                <span>CEO Of Gloz</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non ullamcorper tortor, vel mattis mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <div class="client-card-img">
                    <img src="assets/img/client/client-img4.png" alt="Images">
                </div>
            </div>

            <div class="client-card">
                <h3>James Nilson</h3>
                <span>Manager Of Gloz</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non ullamcorper tortor, vel mattis mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <div class="client-card-img">
                    <img src="assets/img/client/client-img3.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Client Area End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Presence And Be A Leader Of The World</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla amet bibendum ante. Vestibulum ante ipsum primis in faucibus orci.</p>
            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Consulting Quote</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ante nisi, feugiat vel leo eget, dictum.</p>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required data-error="Write your message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->