<!-- Inner Banner -->
<div class="inner-banner inner-bg4">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Blog Details</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Blog Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Blog Details Area -->
<div class="blog-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-article">
                    <div class="blog-article-img">
                        <img src="assets/img/blog/blog-details1.jpg" alt="Images">
                    </div>

                    <div class="blog-status">
                        <div class="row">
                            <div class="col-lg-7 col-sm-7">
                                <ul>
                                    <li>Written by <a routerLink="/blog-1">Admin</a></li>
                                    <li>21  August, 2020</li>
                                </ul>
                            </div>

                            <div class="col-lg-5 col-sm-5">
                                <div class="blog-comment">
                                    <h3><i class='bx bxs-message-detail'></i> 32 Comments</h3> 
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="article-content">
                        <h2>Good Planning and Strategy is the Kye of a Successful Business</h2>

                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquipex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>

                        <p>Ecespiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos quiratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quci velit modi tempora inciduntut labore et dolore magnam aliquam quaerat voluptatem.</p>

                        <blockquote class="blockquote"> 
                            <p>Awesome dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitationaco laboris nisi ut aliquip commodo consequat. </p>
                        </blockquote>
                    </div>

                    <div class="another-content">
                        <div class="content-img">
                            <div class="row">
                                <div class="col-6 col-md-12">
                                    <img src="assets/img/blog/blog-details2.jpg" alt="Images">
                                </div>

                                <div class="col-6 col-md-12">
                                    <img src="assets/img/blog/blog-details3.jpg" alt="Images">
                                </div>
                            </div>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                        <p>consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.</p>
                    </div>

                    <div class="blog-article-share">
                        <ul class="social-icon">
                            <li>Share This Post</li>
                            <li><a target="_blank" href="#"><i class="bx bxl-facebook"></i></a></li>
                            <li><a target="_blank" href="#"><i class="bx bxl-twitter"></i></a></li>
                            <li><a target="_blank" href="#"><i class="bx bxl-instagram"></i></a></li>
                            <li><a target="_blank" href="#"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a target="_blank" href="#"><i class="bx bxl-youtube"></i></a></li>
                        </ul>
                    </div>

                    <div class="comments-wrap">
                        <h3 class="title">Comments</h3>

                        <ul>
                            <li>
                                <img src="assets/img/blog/blog-profile1.png" alt="Image">
                                <h3>John Doe</h3>
                                <span>17th May,2020</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
                                <a routerLink="/blog-details">Reply</a>
                            </li>

                            <li>
                                <img src="assets/img/blog/blog-profile2.png" alt="Image">
                                <h3>Henry Lana</h3>
                                <span>17th May,2020</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
                                <a routerLink="/blog-details">Reply</a>
                            </li>
                            
                            <li>
                                <img src="assets/img/blog/blog-profile3.png" alt="Image">
                                <h3>Alina Decros</h3>
                                <span>17th May,2020</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.</p>
                                <a routerLink="/blog-details">Reply</a>
                            </li>
                        </ul>
                    </div>

                    <div class="comments-form">
                        <div class="contact-form">
                            <div class="section-title">
                                <h2>Leave a comment</h2>
                            </div>

                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="text" name="name" id="name" class="form-control" placeholder="Your Name">
                                        </div>
                                    </div>
    
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" placeholder="Your Message"></textarea>
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn">Post A Comment</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="side-bar-wrap">
                    <div class="search-widget">
                        <form class="search-form">
                            <input type="search" class="form-control" placeholder="Search...">
                            <button type="submit"><i class="bx bx-search"></i></button>
                        </form>
                    </div>

                    <div class="side-bar-widget">
                        <h3 class="title">Categories</h3>

                        <div class="side-bar-categories">
                            <ul>
                                <li>
                                    <a routerLink="/blog-1">Business Planning </a>
                                    <span>(1)</span>
                                </li>
                                <li>
                                    <a routerLink="/blog-1">Project Managment </a>
                                    <span>(2)</span>
                                </li>
                                <li>
                                    <a routerLink="/blog-1">Human Resources </a>
                                    <span>(3)</span>
                                </li>
                                <li>
                                    <a routerLink="/blog-1">Business Consulting </a>
                                    <span>(4)</span>
                                </li>
                                <li>
                                    <a routerLink="/blog-1">Project</a>
                                    <span>(5)</span>
                                </li>
                                <li>
                                    <a routerLink="/blog-1">Strategy</a>
                                    <span>(6)</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="side-bar-widget">
                        <h3 class="title">Recent Posts</h3>

                        <div class="widget-popular-post">
                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="full-image cover bg1" role="img"></span></a>
                                <div class="info">
                                    <h4 class="title-text"><a routerLink="/blog-details">Success Depends on Strategy and Plan</a></h4>
                                    <p>March 20, 2020</p>
                                </div>
                            </article>
                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="full-image cover bg2" role="img"></span></a>
                                <div class="info">
                                    <h4 class="title-text"><a routerLink="/blog-details">Reaching the Goal With Best Consulting</a></h4>
                                    <p>June 11, 2020</p>
                                </div>
                            </article>
                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="full-image cover bg3" role="img"></span></a>
                                <div class="info">
                                    <h4 class="title-text"><a routerLink="/blog-details">Aim is to Reach My Goal With Best Plan</a> </h4>
                                    <p>August 10, 2020</p>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="side-bar-widget">
                        <h3 class="title">Tags</h3>

                        <ul class="side-bar-widget-tag">
                            <li><a routerLink="/blog-1">Business</a></li>
                            <li><a routerLink="/blog-1">Planning</a></li>
                            <li><a routerLink="/blog-1">Project</a></li>
                            <li><a routerLink="/blog-1">Managment</a></li>
                            <li><a routerLink="/blog-1">Strategy</a></li>
                            <li><a routerLink="/blog-1">Resources</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Blog Details Area End -->